import React from "react";
import styled from "styled-components";

import SEO from "../components/seo";
import Header from "../components/header";
import Footer from "../components/footer";
import ContactForm from "../components/contact-form";

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const ContactMain = styled.main`
  z-index: 100;
  flex-grow: 1;
  position: relative;
  padding: 0 4vw;
  padding-bottom: 4vw;
  background: rgb(255, 255, 255);
`

const ContactContent = styled.article`
  position: relative;
  z-index: 50;

  header {
    max-width: 1040px;
    text-align: center;
    margin: 0px auto;
    padding: 6vw 3vw 3vw;

    @media(max-width: 500px) {
      padding: 14vw 3vw 10vw;
    }

    h1 {
      font-size: 3rem;
      font-weight: 700;
      margin: 0px;

      @media(max-width: 500px) {
        font-size: 1.5rem;
      }
    }
  }

  .post-full-content {
    padding-top: 0px;
  }

  section {
    position: relative;
    min-height: 230px;
    font-family: Georgia, serif;
    font-size: 1.2rem;
    line-height: 1.6em;
    margin: 0px auto;
    padding: 70px 100px 0px;
    background: rgb(255, 255, 255);

    @media(max-width: 1170px) {
      padding: 5vw 7vw 0px;
    }

    @media(max-width: 800px) {
      font-size: 1rem;
    }

    @media(max-width: 500px) {
      padding: 0px;
    }

    p {
      margin: 0px 0px 1.5em;
    }
  }
`

const ContactPage = () => {
  return (
    <Contact>
      <SEO title="About" />
      <Header small />
      <ContactMain>
        <ContactContent>
          <header>
            <h1>Contact</h1>
          </header>
          <section className="post-full-content">
            <p>If you'd like to get in touch please fill in the this form.</p>
            <ContactForm/>
          </section>
        </ContactContent>
      </ContactMain>
      <Footer/>
    </Contact>
  );
};

export default ContactPage;
