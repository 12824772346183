import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import AlertBox from "../components/alert";

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      display: "flex",
      flexDirection: "column"
    },
  }
}));

export default function ContactForm() {
  const [sendEnabled, setSendEnabled] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [grc, setGrc] = React.useState("");
  const [msgOpen, setMsgOpen] = React.useState(false);

  const blankAll = () => {
    setName("");
    setEmail("");
    setMessage("");
    setGrc("");
    setSendEnabled(false);
  }

  const setNameAndCheck = (n) => {
    setName(n);
    setSendEnabled(n !== "" && email !== "" && message !== "" && grc !== "");
  }

  const setEmailAndCheck = (e) => {
    setEmail(e);
    setSendEnabled(name !== "" && e !== "" && message !== "" && grc !== "");
  }

  const setMessageAndCheck = (m) => {
    setMessage(m);
    setSendEnabled(name !== "" && email !== "" && m !== "" && grc !== "");
  }

  const setGrcAndCheck = (g) => {
    //console.log(g);
    g = g === null ? "" : g;
    setGrc(g);
    setSendEnabled(name !== "" && email !== "" && message !== "" && g !== "");
  }

  const verifyEmailAddress = (address) => {
    var pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return pattern.test(address);
  }

  const submitForm = () => {
    if(verifyEmailAddress(email)) {
      // email is okay let's go
      setEmailError("");
      // invoke API
      setSendEnabled(false);
      axios({
        method: "POST",
        url: "https://blogapi.richardjameskendall.com/api/send-email",
        //url: "https://api.rjk.codes/api/send-email",
        //url: "https://blog-api.richardjkendall.now.sh/api/send-email",
        data: {
          name: name,
          email: email,
          message: message,
          grc: grc
        },
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(function(r) {
        //console.log("message sent");
        blankAll();
        window.location.href = "/thanks/";
      })
      .catch(function(e) {
        //console.log("there was an error");
        //console.log(e);
        setSendEnabled(true);
        setMsgOpen(true);
      });
    } else {
      setEmailError("Please enter a valid email address");
    }
  }

  const classes = useStyles();

  return (
    <form className={classes.root}>
      <TextField id="user-name" label="Your Name" variant="outlined" required
        onChange={e => setNameAndCheck(e.target.value)}
      />
      <TextField id="user-email" label="Your Email Address" variant="outlined" required 
        onChange={e => setEmailAndCheck(e.target.value)}
        error={emailError !== ""}
        helperText={emailError}
      />
      <TextField id="user-message" label="Your Message" variant="outlined" multiline rows={5} rowsMax={10} required
        onChange={e => setMessageAndCheck(e.target.value)}
      />
      <ReCAPTCHA
        sitekey="6LdH7NAUAAAAAFcrqjPP2hKs2nQj5ProK_IvxzXe"
        onChange={e => setGrcAndCheck(e)}
      />
      <Button variant="contained" color="primary" disabled={!sendEnabled} onClick={submitForm}>
        Send
      </Button>
      <AlertBox
        open={msgOpen}
        title="Something went wrong"
        message="There was an issue sending your message.  Please try again."
        handleClose={() => {setMsgOpen(false)}}
      />
    </form>
  );
}